// export const MerchantToken = "693CA30F-128D-4A3D-9C7B-8E70A2717C51";
// let typefloder = "Index";
// if (/\/productgroup.+/i.test(window.location.pathname)) {
//   typefloder = "ProductGroup";
// }

export default {
  // baseUrl: location.origin.includes("http://localhost")
  //   ? "http://manager.reportauto.cn"
  //   : location.origin,
  baseUrl:process.env.NODE_ENV === 'production' ? 'https://cancelverification.reportauto.cn/' : '/api',
};
