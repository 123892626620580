import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
export default new VueRouter({
  mode: 'history',
  routes: [
    {
      path: "/login",
      name: "login",
      component: () => import("@/components/Login/index.vue"),
      meta: {
        keepAlive: true
      }
    },
    {
      path: "/privacy",
      name: "privacy",
      component: () => import("@/components/Login/privacy.vue"),
    },
    {
      path: "/home",
      name: "home",
      component: () => import("@/components/Home/index.vue"),
    },
    {
      name:"order",
      path:'/order',
      component:()=>import("@/components/Order/index.vue")
    },
    {
      path: "/activity",
      name: "activity",
      component: () => import("@/components/activity/index.vue"),
    },
    {
      path: "/activity",
      name: "activity",
      component: () => import("@/components/activity/index.vue"),
    },{
      path: "/detail",
      name: "detail",
      component: () => import("@/components/activity/detail.vue"),
    },{
      path: "/verification",
      name: "verification",
      component: () => import("@/components/verification/index.vue"),
    },{
      path: "/addresslist",
      name: "addresslist",
      component: () => import("@/components/verification/addressList.vue"),
    },{
      path: "/address",
      name: "address",
      component: () => import("@/components/verification/address.vue"),
    },{
      path: "/product",
      name: "product",
      component: () => import("@/components/product/product.vue"),
    },{
      path: "/user",
      name: "user",
      component: () => import("@/components/user/index.vue"),
    },{
      path: "/message",
      name: "message",
      component: () => import("@/components/message/index.vue"),
    },
    {
      path: "/",
      redirect: "/home",
    },
  ],
});
