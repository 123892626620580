/**
 * 全站http配置
 *
 * axios参数说明
 * isSerialize是否开启form表单提交
 * isToken是否需要token
 */
import axios from 'axios'
import config from "./config.js";
import {Toast} from 'vant'
import { getAppCode } from "@/api/index.js";
import crypto from "@/utils/crypto";
import {getToken, setToken} from '@/utils/auth'
import {Base64} from 'js-base64';
let isRefreshing = false
let requestList = []
axios.defaults.baseURL = config.baseUrl
axios.defaults.timeout = 20000;
//返回其他状态吗
axios.defaults.validateStatus = function (status) {
  return status >= 200 && status <= 500; // 默认的
};
//跨域请求，允许保存cookie
axios.defaults.withCredentials = true;
//HTTPrequest拦截
axios.interceptors.request.use(config => {
  const meta = (config.meta || {});
  const isToken = meta.isToken === false;
  config.headers['Authorization'] = `Basic ${Base64.encode(`${'saber'}:${'saber_secret'}`)}`;
  //headers传递token是否加密
  const cryptoToken = config.cryptoToken === true;
  const token = getToken()
  if (token && !isToken) {
    config.headers['Blade-Auth'] = cryptoToken
      ? 'crypto ' + crypto.encrypt(token)
      : 'bearer ' + token;
  }

  return config
}, error => {
  return Promise.reject(error)
});
//HTTPresponse拦截
axios.interceptors.response.use(async (response) => {
	let res = response.data
  const cryptoToken = response.config.cryptoToken === true;
	// 为了演示，这里仅展示处理状态码为401的情况
  if (res.code !== 200) {
    if(res.msg !== '请求未授权'){
      Toast(res.msg)
    }
  }
	if (res.code == 401) {
		// 控制是否在刷新token的状态
		localStorage.removeItem('token')
		// localStorage.removeItem('userInfo')
		if (!isRefreshing) {
			// 修改isRefreshing状态
			isRefreshing = true
			// 这里是获取新token的接口，方法在这里省略了。
      let newdata = new FormData();
      newdata.append('refreshToken', localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).refreshToken : '')
      newdata.append('grantType', 'refresh_token')
			const result = await getAppCode(newdata)
			// 获取成功
			if (result && result.data) {
				// 新token
				let newToken = result.data.accessToken
				// 保存新的accessToken
				localStorage.setItem('token', newToken)
				setToken(newToken)
				localStorage.setItem('userInfo', JSON.stringify(result.data))
				// 替换新accessToken
				response.config.headers['Blade-Auth'] =  cryptoToken
        ? 'crypto ' + crypto.encrypt(newToken)
        : 'bearer ' + newToken
				// token 刷新后将数组里的请求队列方法重新执行
				requestList.forEach((cb) => cb(newToken))
				// 重新请求完清空
				requestList = []

				// 继续未完成的请求
				const resp = await axios.request(response.config)
				// 重置状态
				isRefreshing = false
				// 返回请求结果
				return resp
			} else {
				// 清除token
				localStorage.clear()
				// 重置状态
				isRefreshing = false
				// 跳转到登录页
				router.replace('/home')
			}
		} else {
			// 后面的请求走这里排队
			// 返回未执行 resolve 的 Promise
			return new Promise(resolve => {
				// 用函数形式将 resolve 存入，等待获取新token后再执行
				requestList.push(newToken => {
          console.log('刷新的token',newToken)
					response.config.headers['Blade-Auth'] = cryptoToken
          ? 'crypto ' + crypto.encrypt(newToken)
          : 'bearer ' + newToken
					resolve(axios(response.config))
				})
			})
		}
	}
	return res
}, error => {
	// 返回错误信息
	return Promise.reject(error)
})
// axios.interceptors.response.use(async (res) => {
//   // 控制是否在刷新token的状态
//   if (!isRefreshing) {
//     // 修改isRefreshing状态
//     isRefreshing = true
//     // 这里是获取新token的接口，方法在这里省略了。
//     const oldtoken = getToken()
//     const result = await getAppCode({
//       grantType: 'refresh_token',
//       refreshToken: oldtoken
//     })
//     // 获取成功
//     if (result && result.data) {
//       // 新token
//       let newToken = result.data.token
//       // 保存新的accessToken
//       localStorage.setItem('token', newToken)
//       // 替换新accessToken
//       response.config.headers.token = newToken

//       // 继续未完成的请求
//       const resp = await axios.request(response.config)
//       // 重置状态
//       isRefreshing = false
//       // 返回请求结果
//       return resp
//     } else {
//       // 清除token
//       localStorage.clear()
//       // 重置状态
//       isRefreshing = false
//       // 跳转到登录页
//       router.replace('/login')
//     }
//   }
//   const status = res.data.code || 200
//   // const statusWhiteList = website.statusWhiteList || [];
//   const statusWhiteList = [];
//   const message = res.data.msg || '未知错误';
//   //如果在白名单里则自行catch逻辑处理
//   if (statusWhiteList.includes(status)) return Promise.reject(res);
//   //如果是401则跳转到登录页面
//   if (status == 401){
//     localStorage.removeItem('token')
//     window.location.reload()
//   }
//   // 如果请求为非200否者默认统一处理
//   if (status !== 200) {
//     if(message !== '请求未授权'){
//       Toast(message)
//     }
//     return Promise.reject(res.data)
//   }
//   return res.data;
// }, error => {
//   return Promise.reject(new Error(error));
// })

export default axios;
