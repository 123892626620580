<template>
  <div id="app">
    <router-view  v-if="!$route.meta.keepAlive"></router-view>
    <KeepAlive>
      <router-view  v-if="$route.meta.keepAlive"></router-view>
    </KeepAlive>
    <Banner v-show="ifShowBanner"></Banner>
  </div>
</template>

<script>
import { getAppCode } from "@/api/index.js";
import { setToken, getToken } from '@/utils/auth'
export default {
  name: "app",
  data() {
    return {
      ifShowBanner: true,
      code: '',
    }
  },
  mounted() {
    localStorage.setItem('iosUrl', window.location.href)
    this.$bus.$on('ifshowBanner', (condition) => {
      this.ifShowBanner = condition
    })
    // this.getWxH5Code()
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        console.log('切换了',this.ifShowBanner)
        // this.getWxH5Code()
        // const unwantedAuthPage = ['/home']
        // const { fullPath } = val
        // if(val !== '/' && !unwantedAuthPage.includes(fullPath)){
        //   this.getWxH5Code()
        // }
      }
      // handler: function(val, oldVal){
      //     // 这个变量用来存储不希望被授权的页面路由
      //     const unwantedAuthPage = ['/home']

      //     const { fullPath } = val

      //     const info = JSON.parse(localStorage.getItem('wx_user_info')) // 获取授权信息
      //     const {
      //         code, // 授权微信返回的code
      //     } = getUrlParams()

      //     const appid = 'wxd4110f44b7a935b1'.slice(0, 6) // appid前六位
      //     // 微信端且需要授权的页面要判断授权
      //     if (isWeiXin && val !== '/' && !unwantedAuthPage.includes(fullPath)) {
      //         if ((!info?.openid && !code) || (info?.appid !== appid && !code)) { // 如果本地没有openid，并且链接中没有授权的code, 就进行授权
      //             const tempAppId = 'wxd4110f44b7a935b1'
      //             this.doAuth(tempAppId)
      //         } else if (code && code !== info?.code) { // 链接有code,并且和存储的code不同，表示重新授权了
      //             const tempUrl = '后端给的授权接口，传code之后获取用户openid等信息'
      //             request({
      //                 url: `${tempUrl}${code}`,
      //                 method: 'get',
      //             }).then(result => {
      //                 const res = result.data
      //                 const save = { ...res, code, appid }
      //                 // 将后端返回的用户信息存本地，在之后的请求中可以将openid放请求头中传给后端，以验证身份
      //                 localStorage.setItem('wx_user_info', JSON.stringify(save))
      //             })
      //         } else {

      //         }
      //     } else {

      //     }
      // },
    }
  },
  methods: {
    getWxH5Code() {
      const isWechat = String(navigator.userAgent.toLowerCase().match(/MicroMessenger/i)) ===
        "micromessenger"
      if (isWechat) {
        let appid = "wx0e203b4e058cd745"; //自己的微信APPid  可以通过接口让后端给，也可以前端写死
        const code = this.getUrlParam('code'); //是否存在code
        let local = 'https://35100d90v9.vicp.fun';   //根据自己的配置调整
        const info = localStorage.getItem('appCode') // 获取授权信息
        if (!info) {
          //不存在就打开上面的地址进行授权
          window.location.href =
            "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
            appid +
            "&redirect_uri=" +
            encodeURIComponent(local) +
            "&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect";
          //snsapi_base （不弹出授权页面，直接跳转，只能获取用户openid），
          //snsapi_userinfo （弹出授权页面，可通过openid拿到昵称、性别、所在地。
          //并且， 即使在未关注的情况下，只要用户授权，也能获取其信息 ）
        } else {
            let data = new FormData();
            data.append('code', localStorage.getItem('appCode'))
            getAppCode(data).then(res => {
              if (res.code == 200) {
                localStorage.setItem('appCode', res.data.token)
                setToken(res.data.accessToken)
                localStorage.setItem('userInfo', JSON.stringify(res.data))
                if(res.data.bingPhone == 0){
                  this.$router.push('/login')
                }
              }
            })
        }
      }
    },
    getUrlParam(name) {
      const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      const r = window.location.search.substr(1).match(reg)
      if (r != null) {
        return unescape(r[2])
      }
      return null

    },
  },
};
</script>

<style>
.full-screen-skeleton {
  min-height: 100vh; /* 使骨架屏充满整个屏幕 */
}
@supports (bottom: env(safe-area-inset-bottom)) {
  body {
    padding-bottom: env(safe-area-inset-bottom);
    bottom: env(safe-area-inset-bottom);
  }
}

body,
html {
  overflow: auto;
  -webkit-overflow-scrolling: auto;
}

#app {
  width: 100vw;
  height: auto;
}
</style>
