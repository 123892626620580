
export function dateFormatter(formatter, date) {
	date = (date ? new Date(date) : new Date)
	const Y = date.getFullYear() + '',
		M = date.getMonth() + 1,
		D = date.getDate(),
		H = date.getHours(),
		m = date.getMinutes(),
		s = date.getSeconds()
	return formatter.replace(/YYYY|yyyy/g, Y)
		.replace(/YY|yy/g, Y.substr(2, 2))
		.replace(/MM/g, (M < 10 ? '0' : '') + M)
		.replace(/DD/g, (D < 10 ? '0' : '') + D)
		.replace(/HH|hh/g, (H < 10 ? '0' : '') + H)
		.replace(/mm/g, (m < 10 ? '0' : '') + m)
		.replace(/ss/g, (s < 10 ? '0' : '') + s)
}
export function GetParam(url, code) {
	url = url + "";
	let regstr = "/(\\?|\\&)" + code + "=([^\\&]+)/";
	let reg = eval(regstr);
	//eval可以将 regstr字符串转换为 正则表达式
	let result = url.match(reg);
	if (result && result[2]) {
	  return result[2];
	}
  }
  